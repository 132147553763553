import React, { Component } from "react";
import { applyVueInReact } from "vuereact-combined";
import LoadingSpinner from "@/components/LoadingSpinner";
import axios from "@/axios";
import Payment from "../../components/payment/Payment";

class PrabhuTvOTT extends Component {
  LoadingSpinnerComp = applyVueInReact(LoadingSpinner);
  constructor() {
    super();
    this.state = {
      message: "",
      reports: "",
      email: "",
      loading: true,
      amount: 0,
    };
  }

  componentDidMount() {
    this.setState({ loading: false });
  }

  handleEmailChange = (event) => {
    this.setState({ email: event.target.value });
  };
  handleAmountChange = (event) => {
    if (!isNaN(event.target.value)) {
      const newValue = event.target.value;
      if (newValue.length >= 3) {
        const roundValue = parseFloat(parseFloat(newValue).toFixed(2)); // Round to 3 decimal places
        this.setState({ amount: roundValue });
      } else {
        this.setState({ amount: newValue });
      }
    }
  };
  checkPrabhuTvOtt = async (event) => {
    event.preventDefault();
    this.setState({ loading: true });

    if (this.state.email.length === 0) {
      this.setState({ message: "Please enter your email.", loading: false });
    }

    const data = await axios.post(
      "api/v2/services/prabhutv/checkPrabhuTvOTTAccount",
      {
        Email: this.state.email,
      }
    );

    this.setState({ loading: false });
    if (data.data && data.data.response && data.data.response.Code === "000") {
      this.setState({ reports: data.data.response });
    } else {
      this.setState({ message: data.data.response.Message });
    }
  };

  validationBeforePayment = () => {
    if (!(this.state.amount >= 1 && this.state.amount <= 5000)) {
      this.setState({ message: "Amount must be between 1 and 5000." });
      return false;
    }
    return true;
  };

  render() {
    return (
      <div className="container">
        <div className="wallet-form-box card">
          <div className="card-body">
            <a href="/" className="btn btn-close">
              <i className="fa fa-times"></i>
            </a>
            <h4>Prabhu Tv OTT TopUp</h4>
            <div className="form-group">
              {this.state.message && (
                <div className="show-message">{this.state.message}</div>
              )}
            </div>
            <div className="row">
              <div className="col-md-7">
                {this.state.reports ? (
                  //If report
                  <div>
                    <div className="mt-4">
                      <div className="font-weight-bold">Customer Name</div>
                      <div>{this.state.reports.CustomerName}</div>
                    </div>
                    <div className="mt-2">
                      <div className="font-weight-bold">
                        OTT Registered Email
                      </div>
                      <div>{this.state.reports.Email}</div>
                    </div>
                    <div className="mt-2">
                      <div className="font-weight-bold">Balance</div>
                      <div>{this.state.reports.Balance}</div>
                    </div>
                    <div className="mt-2">
                      <div className="form-group">
                        <label>
                          {" "}
                          Amount <i className="fa fa-asterisk"></i>{" "}
                        </label>
                        <input
                          className="form-control"
                          type="number"
                          placeholder="Type Amount and press enter"
                          value={this.state.amount}
                          onChange={this.handleAmountChange}
                        />
                      </div>
                    </div>
                    {/* Payment Division */}
                    <div className="fade-animation">
                      <Payment
                        paymentUri="api/v4/prabhu_tv_ott/payment"
                        paymentPayload={{
                          Email: this.state.email,
                          Amount: this.state.amount,
                        }}
                        setMessage={(message) => {
                          this.setState({ message });
                        }}
                        validationBeforePayment={this.validationBeforePayment}
                        paymentPreviewDetail={{
                          "Customer Name": this.state.reports.CustomerName,
                          "Total Amount to Pay": "Rs. " + this.state.amount,
                        }}
                      />
                    </div>
                  </div>
                ) : (
                  //If no report
                  <form>
                    <div className="form-group">
                      <label>
                        {" "}
                        OTT Registerd Email <i className="fa fa-asterisk"></i>
                      </label>
                      <input
                        className="form-control"
                        type="email"
                        placeholder="Type Email and press enter."
                        value={this.state.email}
                        onChange={this.handleEmailChange}
                      />
                      <button
                        className="btn btn-primary mt-3"
                        onClick={this.checkPrabhuTvOtt}
                      >
                        Search
                      </button>
                    </div>
                  </form>
                )}
              </div>
            </div>
            <this.LoadingSpinnerComp loading={this.state.loading} />
          </div>
        </div>
      </div>
    );
  }
}

export default PrabhuTvOTT;
